<template>
  <v-card class="elevation-0">
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="affected_pk"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      class="elevation-0"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`item.notated_keyword`]="{ item }">
        <router-link class="blue--text darken-1" :to="{ name: 'Negative keyword', params: {nkwID: item.keywords_fk }}">
          {{item.notated_keyword}}
        </router-link>
      </template>

      <template v-slot:[`item.territory`]="{ item }">
        <span v-if="item.region !== 'None'">{{ item.region }}</span>
        <span v-else>{{ item.country }}</span>
      </template>

      <template v-slot:[`item.approval_status`]="{ item }">
        <v-icon>{{ nkw_getApprovalStatusIcon(item.approval_status) }}</v-icon>
      </template>

      <template v-slot:[`item.affected_at`]="{ item }">
        {{ datatables_formatTimestamp(item.affected_at) }}
      </template>

      <template v-slot:[`item.affected_old_values`]="{ item }">
        <table class="inner-table">
          <tbody>
            <tr v-for="(value, key) in item.oldValuesObj" :key="key">
              <td>{{key}}</td><td>{{value}}</td>
            </tr>
          </tbody>
        </table>
      </template>

      <template v-slot:[`item.affected_new_values`]="{ item }">
        <table class="inner-table">
          <tbody>
            <tr v-for="(value, key) in item.newValuesObj" :key="key">
              <td>{{key}}</td><td>{{value}}</td>
            </tr>
          </tbody>
        </table>
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'
import nkwMixin from '@/mixins/negativeKeywords'

export default {
  name: 'NKWChangedBookingsTable',

  data () {
    return {
      headers: [
        { text: 'Keyword', value: 'notated_keyword' },
        { text: 'Vertical', value: 'vertical_short_name' },
        { text: 'Provider', value: 'provider' },
        { text: 'Territory', value: 'territory' },
        { text: 'Approved?', value: 'approval_status' },
        { text: 'Changed at', value: 'affected_at' },
        { text: 'Changed by', value: 'affected_by' },
        { text: 'From', value: 'affected_old_values' },
        { text: 'To', value: 'affected_new_values' },
      ],
      items: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      lsOptions: 'nkwChangedBookings_options',
      dataEndpoint: '/a/nkw/bookings-data-updates',
    }
  },

  mixins: [dataTablesMixin, nkwMixin],

  computed: {
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    fetchData: function () {
      let myURL = this.dataEndpoint + '?xfields='
      this.loading = true

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, ['territory'], ['affected_pk', 'keywords_fk', 'region', 'country', 'id'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems

          // convert the old and new JSON diff properties to objects
          for (var i = 0; i < this.items.length; i++) {
            this.items[i].oldValuesObj = JSON.parse(this.items[i].affected_old_values)
            this.items[i].newValuesObj = JSON.parse(this.items[i].affected_new_values)
          }
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>

<style scoped>
.inner-table tbody tr td:nth-child(1) {
    min-width: 120px;
}
</style>
